<template>
  <div
    class="w-full h-full flex flex-col justify-center items-center bg-gray-900 bg-opacity-80"
  >
    <!-- <h1 class="uppercase font-semibold text-3xl">
         Welcome to the Virtual Rooms
      </h1> -->
    <img
      src="/pwa/images/icons/icon-512x512.png"
      alt="Virtual Rooms Logo"
      class="w-24 rounded-xl mx-auto pulse border border-blue-300"
    />

    <h2 v-if="!newPlayer" class="quote animate-charcter absolute bottom-20">
      Loading
    </h2>
  </div>
</template>

<script>
import { loginFromSkin, signupFromSkin } from "@/api/authRepository";
import userRepository from "@/api/userRepository";
import settingsRepository from "@/api/settingsRepository";
import tokensRepository from "@/api/tokensRepository";
import { getCredit } from "@/api/creditRepository";
import analyticsConfig from "../mixins/analyticsConfig.vue";

export default {
  name: "Auth",

  mixins: [analyticsConfig],

  data() {
    return {
      newPlayer: false,
    };
  },

  created() {
    console.log("created AUTH.VUE");
    this.checkIfIsSkinAuthenticated();
  },

  methods: {
    checkIfIsSkinAuthenticated() {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("token");
      const contractNumber = params.get("contractNumber");
      let skinId = params.get("skinId");
      skinId = parseInt(skinId);

      // se existem e se têm alguma coisa, n basta um skin=
      if (!!token && !!contractNumber && !!skinId) {
        this.$store.dispatch("auth/cleanUpAuth");
        this.$store.dispatch("socket/destroySocket");
        loginFromSkin(token, contractNumber, skinId, false)
          .then((res) => {
            console.log("then do login from skin", token);
            this.$store.dispatch(
              "auth/updateAccessToken",
              res.data.accessToken
            );
            this.$store.dispatch("auth/updateDeviceId", res.data.deviceId);
            this.$store.dispatch(
              "auth/updateRefreshToken",
              res.data.refreshToken
            );
            this.$store.dispatch("auth/updateUserId", res.data.userId);
            this.$store.dispatch("auth/updateIsAuthenticated", true);
            this.$store.dispatch(
              "auth/updateExternalUserId",
              res.data.externalUserId
            );
            this.$store.commit("auth/mutateNumContract", res.data.numContract);

            let promises = [
              //TODO response.data.userId
              userRepository.getUser(res.data.userId),
              //TODO response.data.userId
              settingsRepository.getSettings(res.data.userId),
              //TODO response.data.userId
              getCredit(res.data.userId),
              tokensRepository.getCredit(res.data.userId),
              userRepository.getAvatar(res.data.userId),
            ];

            Promise.all(promises)
              .then(([user, settings, credit, tokens, avatar]) => {
                user.data, settings.data, credit.data, avatar.data;
                this.$store.commit("userInfo/mutateUser", user.data);
                this.$store.commit("userInfo/mutateSettings", settings.data);
                this.$store.commit("userInfo/mutateCredit", credit.data);
                this.$store.commit(
                  "userInfo/mutateAvatarCustom",
                  avatar.data && avatar.data.photo ? avatar.data.photo : null
                );

                this.$store.commit("userInfo/mutateTokens", tokens.data);

                try {
                  if (!!process.env.VUE_APP_EVENTS_SOCKET_SERVER_URL) {
                    this.$store.dispatch("socket/init");
                  }
                } catch (err) {
                  console.error(err);
                }

                ////////////////////////////////////////////////////////////
                this.userIdAnalyticsConfig(user.data.nickname);
                ////////////////////////////////////////////////////////////

                this.$router.push({ name: "SlotsBar" });
              })
              .catch((err) => {
                // TODO implement refresh token logic
                this.$store.dispatch("auth/cleanUpAuth");
                this.$store.dispatch("socket/destroySocket");
                //TODO redirect to skin maybe???
              });
          })
          .catch((err) => {
            if (err.response.status == 404) {
              this.signupFromSkinRequest(token, contractNumber, skinId);
            }
            if (err.response.status == 400 || err.response.status == 403) {
              this.$router.push({ name: "404" });
            }
            if (err.response.status == 302 && !!err.response.data.redirectUrl) {
              window.location.href = err.response.data.redirectUrl;
            }
          });
      } else {
        this.$store.dispatch("auth/cleanUpAuth");
        this.$store.dispatch("socket/destroySocket");
        this.$router.push({ name: "Login" });
      }
    },

    signupFromSkinRequest(token, contractNumber, skinId) {
      signupFromSkin(token, contractNumber, skinId)
        .then((res) => {
          this.authPolling(token, contractNumber, skinId);
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status == 302 && !!e.response.data.redirectUrl) {
            window.location.href = e.response.data.redirectUrl;
          }
          if (e.response.status == 404) {
            this.$router.push({ name: "404" });
          }
        });
    },

    authPolling(token, contractNumber, skinId) {
      loginFromSkin(token, contractNumber, skinId, true)
        .then((res) => {
          this.$store.dispatch("auth/updateAccessToken", res.data.accessToken);
          this.$store.dispatch("auth/updateDeviceId", res.data.deviceId);
          this.$store.dispatch(
            "auth/updateRefreshToken",
            res.data.refreshToken
          );
          this.$store.dispatch("auth/updateUserId", res.data.userId);
          this.$store.dispatch(
            "auth/updateExternalUserId",
            res.data.externalUserId
          );
          this.$store.dispatch("auth/updateIsAuthenticated", true);
          this.$store.commit("auth/mutateNumContract", res.data.numContract);

          let promises = [
            //TODO response.data.userId
            userRepository.getUser(res.data.userId),
            //TODO response.data.userId
            settingsRepository.getSettings(res.data.userId),
            //TODO response.data.userId
            getCredit(res.data.userId),
            tokensRepository.getCredit(res.data.userId),
            userRepository.getAvatar(res.data.userId),
          ];

          Promise.all(promises)
            .then(([user, settings, credit, tokens, avatar]) => {
              // user.data, settings.data, credit.data, avatar.data;
              this.$store.commit("userInfo/mutateUser", user.data);
              this.$store.commit("userInfo/mutateSettings", settings.data);
              this.$store.commit("userInfo/mutateCredit", credit.data);
              this.$store.commit("userInfo/mutateTokens", tokens.data);
              this.$store.commit(
                "userInfo/mutateAvatarCustom",
                avatar.data && avatar.data.photo ? avatar.data.photo : null
              );

              try {
                if (!!process.env.VUE_APP_EVENTS_SOCKET_SERVER_URL) {
                  this.$store.dispatch("socket/init");
                }
              } catch (err) {
                console.error(err);
              }

              ////////////////////////////////////////////////////////////
              // this.userIdAnalyticsConfig(user.data.nickname);
              ////////////////////////////////////////////////////////////

              this.$router.push({ name: "SlotsBar" });
            })
            .catch((err) => {
              // TODO implement refresh token logic
              this.$store.dispatch("auth/cleanUpAuth");
              this.$store.dispatch("socket/destroySocket");
              s;
            });
        })
        .catch((err) => {
          if (err.response.status == 403) {
            this.$router.push({ name: "404" });
          }
          if (err.response.status == 503) {
            let timeout = 1000;
            setTimeout(() => {
              this.authPolling(token, contractNumber, skinId);
            }, timeout);
          }
        });
    },
  },
};
</script>

<style lang="postcss" scoped>
h2.animate-charcter {
  background-image: linear-gradient(
    125deg,
    #54d2ee 0%,
    #232e51 50%,
    #54d2ee 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  /* color: #fff; */
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  display: inline-block;
  @apply font-semibold text-2xl uppercase;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

img {
  animation: pulsate 5s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1); /* Original size */
    box-shadow: 0px 0px 10px 1px #64adf6;
  }
  50% {
    transform: scale(1.1); /* Slightly larger size */
    box-shadow: 0px 0px 30px 3px #64adf6;
  }
  100% {
    transform: scale(1); /* Back to original size */
    box-shadow: 0px 0px 10px 1px #64adf6;
  }
}
</style>
