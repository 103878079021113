var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-full h-full flex flex-col justify-center items-center bg-gray-900 bg-opacity-80",
    },
    [
      _c("img", {
        staticClass: "w-24 rounded-xl mx-auto pulse border border-blue-300",
        attrs: {
          src: "/pwa/images/icons/icon-512x512.png",
          alt: "Virtual Rooms Logo",
        },
      }),
      !_vm.newPlayer
        ? _c(
            "h2",
            { staticClass: "quote animate-charcter absolute bottom-20" },
            [_vm._v(" Loading ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }